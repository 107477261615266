const bindings = [
  {
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Zoom' },
    label: 'Zoom',
    keys: ['z'],
    isEditable: true,
  },
  {
    commandName: 'scaleUpViewport',
    label: 'Zoom In',
    keys: ['+'],
    isEditable: true,
  },
  {
    commandName: 'scaleDownViewport',
    label: 'Zoom Out',
    keys: ['-'],
    isEditable: true,
  },
  {
    commandName: 'fitViewportToWindow',
    label: 'Zoom to Fit',
    keys: ['='],
    isEditable: true,
  },
  {
    commandName: 'rotateViewportCW',
    label: 'Rotate Right',
    keys: ['r'],
    isEditable: true,
  },
  {
    commandName: 'rotateViewportCCW',
    label: 'Rotate Left',
    keys: ['l'],
    isEditable: true,
  },
  {
    commandName: 'flipViewportHorizontal',
    label: 'Flip Horizontally',
    keys: ['h'],
    isEditable: true,
  },
  {
    commandName: 'flipViewportVertical',
    label: 'Flip Vertically',
    keys: ['v'],
    isEditable: true,
  },
  {
    commandName: 'toggleCine',
    label: 'Cine',
    keys: ['c'],
  },
  {
    commandName: 'invertViewport',
    label: 'Invert',
    keys: ['i'],
    isEditable: true,
  },
  {
    commandName: 'incrementActiveViewport',
    label: 'Next Image Viewport',
    keys: ['right'],
    isEditable: true,
  },
  {
    commandName: 'decrementActiveViewport',
    label: 'Previous Image Viewport',
    keys: ['left'],
    isEditable: true,
  },
  {
    commandName: 'updateViewportDisplaySet',
    commandOptions: {
      direction: -1,
    },
    label: 'Previous Series',
    keys: ['pageup'],
    isEditable: true,
  },
  {
    commandName: 'updateViewportDisplaySet',
    commandOptions: {
      direction: 1,
    },
    label: 'Next Series',
    keys: ['pagedown'],
    isEditable: true,
  },
  {
    commandName: 'nextStage',
    context: 'DEFAULT',
    label: 'Next Stage',
    keys: ['.'],
    isEditable: true,
  },
  {
    commandName: 'previousStage',
    context: 'DEFAULT',
    label: 'Previous Stage',
    keys: [','],
    isEditable: true,
  },
  {
    commandName: 'nextImage',
    label: 'Next Image',
    keys: ['down'],
    isEditable: true,
  },
  {
    commandName: 'previousImage',
    label: 'Previous Image',
    keys: ['up'],
    isEditable: true,
  },
  {
    commandName: 'firstImage',
    label: 'First Image',
    keys: ['home'],
    isEditable: true,
  },
  {
    commandName: 'lastImage',
    label: 'Last Image',
    keys: ['end'],
    isEditable: true,
  },
  {
    commandName: 'resetViewport',
    label: 'Reset',
    keys: ['space'],
    isEditable: true,
  },
  {
    commandName: 'cancelMeasurement',
    label: 'Cancel Measurement',
    keys: ['esc'],
  },
  {
    commandName: 'setWindowLevelPreset',
    commandOptions: { presetName: 'ct-soft-tissue', presetIndex: 0 },
    label: 'W/L Preset 1',
    keys: ['1'],
  },
  {
    commandName: 'setWindowLevelPreset',
    commandOptions: { presetName: 'ct-lung', presetIndex: 1 },
    label: 'W/L Preset 2',
    keys: ['2'],
  },
  {
    commandName: 'setWindowLevelPreset',
    commandOptions: { presetName: 'ct-bone', presetIndex: 2 },
    label: 'W/L Preset 3',
    keys: ['3'],
  },
  {
    commandName: 'setWindowLevelPreset',
    commandOptions: { presetName: 'ct-brain', presetIndex: 3 },
    label: 'W/L Preset 4',
    keys: ['4'],
  },
  {
    commandName: 'deleteActiveAnnotation',
    label: 'Delete Annotation',
    keys: ['backspace'],
  },
  {
    commandName: 'acceptPreview',
    label: 'Accept Preview',
    keys: ['enter'],
  },
  {
    commandName: 'rejectPreview',
    label: 'Reject Preview',
    keys: ['esc'],
  },
  {
    commandName: 'undo',
    label: 'Undo',
    keys: ['ctrl+z'],
    isEditable: true,
  },
  {
    commandName: 'redo',
    label: 'Redo',
    keys: ['ctrl+y'],
    isEditable: true,
  },
  {
    commandName: 'interpolateScrollForMarkerLabelmap',
    label: 'Interpolate Scroll',
    keys: ['n'],
    isEditable: true,
  },
  {
    commandName: 'increaseBrushSize',
    label: 'Increase Brush Size',
    keys: [']'],
    isEditable: true,
  },
  {
    commandName: 'decreaseBrushSize',
    label: 'Decrease Brush Size',
    keys: ['['],
    isEditable: true,
  },
  {
    commandName: 'setToolActive',
    commandOptions: { toolName: 'CircularEraser' },
    label: 'Eraser',
    keys: ['e'],
    isEditable: true,
  },
  {
    commandName: 'setToolActive',
    commandOptions: { toolName: 'CircularBrush' },
    label: 'Brush',
    keys: ['b'],
    isEditable: true,
  },
  {
    commandName: 'addNewSegment',
    label: 'Add New Segment',
    keys: ['a'],
    isEditable: true,
  },
];

export default bindings;
